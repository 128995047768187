import { useCallback } from 'react';
import useSWR from 'swr';
import { Commission, FetchResult } from '../entities';
import { AxiosResponse } from 'axios';
import { axiosBackend } from '../api/backend';

type Result = FetchResult & {
  commission: Commission[];
  saveCommission: (commission: Commission) => Promise<AxiosResponse<any>>;
  deleteCommission: (invoiceId: string) => Promise<AxiosResponse<any>>;
};

export function useUserCommission(userId?: number): Result {
  const { data, error, mutate } = useSWR(userId ? `/commission/by-user/${userId}` : '');

  const saveCommission = useCallback((commission: Commission) => axiosBackend.post('commission', commission), []);
  const deleteCommission = useCallback((commissionId: string) => axiosBackend.delete(`commission/${commissionId}`), []);

  return {
    commission: data,
    isLoading: !error && !data,
    isError: error,
    mutate,
    saveCommission,
    deleteCommission,
  };
}
