import moment from 'moment';
import { Moment } from 'moment';
import { RangePickerProps } from 'antd/es/date-picker';
import { Project } from '.';

export type RangeValueType = RangePickerProps['value'];

export interface BillingAddress {
  address: string;
  apartment: string;
  zip: string;
  phone: string;
}

interface BillingDetails {
  name: string;
  billingAddress: BillingAddress;
}

export interface Invoice {
  id?: string;
  ordinal: number;
  autoCode: string;
  invoiceNumber: string;
  value: number;
  actualCost: number;
  paid: boolean;
  description?: string;
  clientNote?: string;
  invoiceLink: string;
  clientInvoiceLink: string;
  due?: string;
  estimatedPayDate?: Moment | Date | null;
  sentDate?: Moment | Date | null;
  paidDate?: Moment | Date | null;
  forMonth?: Moment | Date | null;
  isActive?: boolean;
  groupByPhase: boolean;
  dateRange: RangeValueType;
  details: InvoiceLineItem[];
  markupPercentage: number;
  sellerDetails?: BillingDetails;
  buyerDetails?: BillingDetails;
  project?: Project;
  projectId?: string;
}

export interface InvoiceLineItem {
  key: string;
  billableHours: number;
  billableTotal: number;
  billableRate: number;
  fixedBillableTotal: number;
  totalCost: number;
  fixedCost: number;
  totalMinutes: number | string;
  phaseName: string;
  category: string;
  isCommission?: boolean;
  lineItemName?: string;
  lineItemAmount?: number;
}

export interface GroupableTimesheetEntry {
  billableHours: number;
  billableTotal: number;
  totalCost: number;
  totalMinutes: number | string;
  fixedCost: number;
  fixedBillableTotal: number;
  phaseName: string;
  category: string;
  lineItemName?: string;
  lineItemAmount?: number;
}

export const getInvoiceStatus = (
  invoice: Invoice,
  fieldKey?: number,
  payDateFullness?: boolean[],
  estimatedPayDates?: (moment.Moment | Date | null | undefined)[],
  isActiveInvoices?: boolean[],
) => {
  const isActive = isActiveInvoices?.length && fieldKey !== undefined ? isActiveInvoices[fieldKey] : invoice?.isActive;
  const isPaid = payDateFullness?.length && fieldKey !== undefined ? payDateFullness[fieldKey] : invoice?.paidDate;

  if (!isActive) {
    return { statusColor: 'draft-invoice', statusText: 'Draft' };
  }

  if (isPaid) {
    return { statusColor: 'paid-invoice', statusText: 'Paid' };
  }

  const estimatedPayDate = moment(
    estimatedPayDates?.length && fieldKey !== undefined ? estimatedPayDates[fieldKey] : invoice?.estimatedPayDate,
  ).toDate();

  const isOverdue = estimatedPayDate.getTime() < Date.now();

  return {
    statusColor: isOverdue ? 'overdue-invoice' : 'active-invoice',
    statusText: isOverdue ? 'Overdue' : 'Active',
  };
};

export const getInvoiceRowCssClass = (invoice: Invoice) => {
  if (!invoice.isActive) return 'draft-invoice';

  if (invoice.paidDate) return 'paid-invoice';

  const isOverdue = moment(invoice.estimatedPayDate).isBefore(moment());
  return isOverdue ? 'overdue-invoice' : 'active-invoice';
};
