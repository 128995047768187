import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/home';
import Projects from './pages/projects/Index';
import DirectChats from './pages/direct-chats';
import Users from './pages/users';
import Clients from './pages/clients/Index';
import Timesheet from './pages/timesheet/Index';
import Client from './pages/clients/[id]';
import { useProjects, useSession } from './dal';
import User from './pages/users/[id]';
import SettingsMain from './pages/SettingsMain';
import ReportsMain from './pages/reports';
import Tasks from './pages/tasks';
import Presentations from './pages/presentations/Index';
import Presentation from './pages/presentations/[id]';
import { usePermissions } from './common/usePermissions/usePermissions';
import {
  ReportPermissions,
  SettingsPermissions,
  PresentationsPermissions,
  TasksPermissions,
  UserPermisions,
  ClientPermissions,
  TicketsPermissions,
} from './common/usePermissions/permissions';
import Tickets from './pages/tickets';
import SalesReport from './pages/reports/sales';
import CashFlowReport from './pages/reports/cashflow';
import TimesheetReport from './pages/reports/timesheet';
import Approvals from './pages/reports/approvals';
import Payroll from './pages/reports/payroll';
import BudgetReport from './pages/reports/budget';
import EmployeeRates from './pages/reports/employee-rates';
import ProjectsTable from './components/project/ProjectsTable';
import { PortfolioTableForm } from './components/portfolio/PortfolioTableForm';
import CommissionReport from './pages/reports/commission';

const App = () => {
  const { hasPermission } = usePermissions();

  const { projects = [] } = useProjects();

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      {hasPermission(ClientPermissions.CLIENT_VIEW) && (
        <Route
          path="/clients/:id"
          element={
            <PrivateRoute>
              <Client />
            </PrivateRoute>
          }
        />
      )}
      {hasPermission(ClientPermissions.CLIENT_VIEW) && (
        <Route
          path="/clients"
          element={
            <PrivateRoute>
              <Clients />
            </PrivateRoute>
          }
        />
      )}
      <Route
        path="/projects"
        element={
          <PrivateRoute>
            <Projects />
          </PrivateRoute>
        }
      />
      <Route
        path="/direct-chats"
        element={
          <PrivateRoute>
            <DirectChats />
          </PrivateRoute>
        }
      />
      {hasPermission(UserPermisions.USER_READ) && (
        <Route
          path="/users/:id"
          element={
            <PrivateRoute>
              <User />
            </PrivateRoute>
          }
        />
      )}
      {hasPermission(UserPermisions.USER_READ) && (
        <Route
          path="/users"
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />
      )}
      <Route
        path="/timesheet"
        element={
          <PrivateRoute>
            <Timesheet />
          </PrivateRoute>
        }
      />
      {hasPermission(TasksPermissions.TASKS_READ) && (
        <Route
          path="/tasks"
          element={
            <PrivateRoute>
              <Tasks />
            </PrivateRoute>
          }
        />
      )}
      {hasPermission(TicketsPermissions.TICKETS_READ_ALL) && (
        <Route
          path="/tickets"
          element={
            <PrivateRoute>
              <Tickets />
            </PrivateRoute>
          }
        />
      )}
      {hasPermission(PresentationsPermissions.PRESENTATIONS_WRITE) && (
        <Route
          path="/presentations/:id"
          element={
            <PrivateRoute>
              <Presentation />
            </PrivateRoute>
          }
        />
      )}
      {hasPermission(PresentationsPermissions.PRESENTATIONS_VIEW) && (
        <Route
          path="/presentations"
          element={
            <PrivateRoute>
              <Presentations />
            </PrivateRoute>
          }
        />
      )}
      {hasPermission(SettingsPermissions.SETTINGS_VIEW) && (
        <>
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <SettingsMain />
              </PrivateRoute>
            }
          />
        </>
      )}
      {hasPermission(ReportPermissions.REPORTS_VIEW) && (
        <Route
          path="/report"
          element={
            <PrivateRoute>
              <ReportsMain />
            </PrivateRoute>
          }
        >
          <Route path="sales" element={<SalesReport />} />
          <Route path="cashflow" element={<CashFlowReport />} />
          <Route path="timesheet" element={<TimesheetReport />} />
          <Route path="approvals" element={<Approvals />} />
          <Route path="payroll" element={<Payroll />} />
          <Route path="budget" element={<BudgetReport />} />
          <Route path="employee-rates" element={<EmployeeRates />} />
          <Route path="all-projects" element={<ProjectsTable projects={projects} filterKey="allprojects" />} />
          <Route path="portfolio" element={<PortfolioTableForm />} />
          <Route path="commission" element={<CommissionReport />} />
        </Route>
      )}
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path="/projects/:id/:tab"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path="/projects/:id/:tab/:tabId"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path="/chat/:id"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path="/projects/:id"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default App;

const PrivateRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { session, isLoading } = useSession();

  if (isLoading) return <></>;

  return session ? children : <Navigate to="/login" />;
  // TODO: need to review permissions in router to make it works normally
  //   return session ? children : <Navigate to="/login" state={{ from: location }} />;
};
