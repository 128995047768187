import { useCallback, useMemo, useState } from 'react';
import { Button, Form, Input, Row, Col, DatePicker, Modal, Space, Alert, Popconfirm, Switch, Typography, List, InputNumber } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { EditOutlined } from '@ant-design/icons';
import { Invoice, InvoiceLineItem } from '../../entities/Invoice';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment';
import { datePickerFormat } from '../../constants';
import MoneyInput from '../shared/MoneyInput';
import InvoiceTable from './InvoiceTable';
import InvoiceCreator from './InvoiceCreator';
import { PlusOutlined } from '@ant-design/icons';
import { InvoiceStatusTag } from './InvoiceStatusTag';
import { subject } from '@casl/ability';
import { useProjectCommission } from '../../dal/useProjectCommission';
import AddLineItemModal from './ProjectFinance.addLineItemModal';

type InvoiceTableInternalProps = {
  value?: InvoiceLineItem[];
  isClientView?: boolean;
  groupByPhase?: boolean;
  onChange?: (count: number) => void;
};

const InvoiceTableInternal: React.FC<InvoiceTableInternalProps> = ({ value, isClientView, groupByPhase }) => {
  return value && value.length > 0 ? <InvoiceTable details={value} isClientView={isClientView} groupByPhase={groupByPhase} /> : null;
};

type Props = {
  projectId: string;
  invoice: Invoice;
  billableRate: number;
  onCancel: () => void;
  onDelete?: ((invoiceId: string) => void) | null;
  onSubmit?: ((invoice: Invoice) => void) | null;
};

const InvoiceModal = ({ projectId, invoice, billableRate, onCancel, onDelete, onSubmit }: Props) => {
  const { ability } = usePermissions();
  const [invoiceForm] = useForm();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [createdInvoice, setCreatedInvoice] = useState<Invoice | undefined>();
  const { commission } = useProjectCommission(projectId);
  const [isActiveInvoice, setIsActiveInvoice] = useState<boolean>(invoice.isActive || false);

  const onInvoiceFormFinish = useCallback(
    async (invoice: Invoice) => {
      if (onSubmit) {
        onSubmit(invoice);
      }
    },
    [onSubmit],
  );

  const onInvoiceEditCompleted = useCallback(
    (data: Invoice) => {
      invoiceForm.setFieldsValue(data);
      setCreatedInvoice(data);
      setIsEditModalOpen(false);
    },
    [invoiceForm],
  );

  const onLineItemAdded = useCallback(
    data => {
      const actualInvoice = invoiceForm.getFieldsValue();
      const updatedDetails = actualInvoice.details.filter(detail => !detail?.lineItemAmount).concat(data);

      const actualValue = updatedDetails.reduce(
        (acc, { billableTotal = 0, fixedBillableTotal = 0, lineItemAmount = 0 }) => acc + billableTotal + fixedBillableTotal + lineItemAmount,
        0,
      );

      const actualMarkup = ((1 - actualInvoice.actualCost / actualValue) * 100).toFixed(2);

      invoiceForm.setFieldsValue({
        ...actualInvoice,
        value: actualValue,
        markupPercentage: actualMarkup,
        details: updatedDetails,
      });

      setIsAddModalOpen(false);
    },
    [invoiceForm],
  );

  const handleValuesChange = useCallback(
    (changedValues: Partial<Invoice>, allValues: Invoice) => {
      if ('value' in changedValues) {
        const value = changedValues.value;
        const actualCost = allValues.actualCost || 0;

        if (value && actualCost > 0) {
          const markupPercentage = ((1 - actualCost / value) * 100).toFixed(2);
          invoiceForm.setFieldsValue({ markupPercentage });
        } else {
          invoiceForm.setFieldsValue({ markupPercentage: 0 });
        }
      }

      if ('isActive' in changedValues) {
        setIsActiveInvoice(changedValues?.isActive || false);
        invoiceForm.setFieldsValue({ paidDate: null });
      }
    },
    [invoiceForm],
  );

  const hasDetails = invoiceForm.getFieldValue('details')?.length > 0;
  const hasDateRange = useMemo(() => invoice.dateRange?.some(date => date !== null), [invoice.dateRange]);

  return (
    <Modal
      open={invoice !== null}
      title={
        <Space size="large">
          <Typography.Text>Invoice {invoice?.autoCode}</Typography.Text>
          <InvoiceStatusTag invoice={invoice} />
        </Space>
      }
      onCancel={() => onCancel()}
      width="min(calc(100% - 40px), 1400px)"
      style={{ top: 20 }}
      destroyOnClose
      footer={[
        <Row justify="space-between">
          <Col>
            {invoice.id && onDelete && (
              <Popconfirm
                title="Do you want to permanently delete this invoice?"
                onConfirm={() => onDelete(invoice.id!)}
                disabled={!ability.can('delete', subject('Invoice', subject('Invoice', invoice)))}
              >
                <Button type="link" danger disabled={!ability.can('delete', subject('Invoice', invoice))}>
                  Delete
                </Button>
              </Popconfirm>
            )}
          </Col>
          <Col>
            <Button type="text" onClick={() => onCancel()}>
              {onSubmit ? 'Cancel' : 'Close'}
            </Button>
            {invoice?.isActive || !onSubmit ? null : (
              <Button
                key="saveAndMakeActive"
                type="primary"
                onClick={() => {
                  invoiceForm.setFieldValue('isActive', true);
                  invoiceForm.submit();
                }}
                disabled={!ability.can('update', subject('Invoice', invoice), 'isActive')}
              >
                Save and make active
              </Button>
            )}
            {onSubmit && (
              <Button
                key="save"
                type="primary"
                disabled={!ability.can('create', subject('Invoice', invoice))}
                onClick={() => {
                  invoiceForm.submit();
                }}
              >
                Save
              </Button>
            )}
          </Col>
        </Row>,
      ]}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        {!invoice?.isActive && <Alert message="This invoice is a draft (not visible to clients)" type="warning" />}
        {invoice && (
          <Form
            form={invoiceForm}
            initialValues={{
              ...invoice,
              estimatedPayDate: invoice.estimatedPayDate ? moment(invoice.estimatedPayDate) : null,
              sentDate: invoice.sentDate ? moment(invoice.sentDate) : null,
              paidDate: invoice.paidDate ? moment(invoice.paidDate) : null,
              forMonth: invoice.forMonth ? moment(invoice.forMonth) : null,
              dateRange: invoice.dateRange ? invoice.dateRange.map(d => (d ? moment(d) : null)) : null,
            }}
            layout="vertical"
            onValuesChange={handleValuesChange}
            onFinish={onInvoiceFormFinish}
          >
            <Row gutter={16}>
              <Col span={3}>
                <Form.Item label="External #" name="invoiceNumber">
                  <Input disabled={!ability.can('update', subject('Invoice', invoice), 'invoiceNumber')} />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Value" name="value">
                  <MoneyInput disabled={!ability.can('update', subject('Invoice', invoice), 'value')} readOnly={hasDetails || hasDateRange} />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Actual cost" name="actualCost">
                  <MoneyInput readOnly />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label="Markup" name="markupPercentage">
                  <InputNumber controls={false} formatter={value => `${value}%`} bordered={false} readOnly />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="For month" name="forMonth">
                  <DatePicker.MonthPicker
                    format="MMMM YYYY"
                    disabled={!ability.can('update', subject('Invoice', invoice), 'forMonth')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="Bill for" name="due">
                  <Input disabled={!ability.can('update', subject('Invoice', invoice), 'due')} />
                </Form.Item>
              </Col>

              <Col span={3}>
                <Form.Item label="Estimated pay date" name="estimatedPayDate">
                  <DatePicker
                    format={datePickerFormat}
                    disabled={!ability.can('update', subject('Invoice', invoice), 'estimatedPayDate')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="Sent date" name="sentDate">
                  <DatePicker
                    format={datePickerFormat}
                    disabled={!ability.can('update', subject('Invoice', invoice), 'sentDate')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item label="Paid date" name="paidDate">
                  <DatePicker
                    format={datePickerFormat}
                    disabled={!ability.can('update', subject('Invoice', invoice), 'paidDate') || !isActiveInvoice}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={20}>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Internal note" name="description">
                      <TextArea rows={4} disabled={!ability.can('update', subject('Invoice', invoice), 'description')} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Client note" name="clientNote">
                      <TextArea rows={4} disabled={!ability.can('update', subject('Invoice', invoice), 'clientNote')} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Internal link" name="invoiceLink" className="invoice-link">
                      <Input.Search
                        enterButton={<Button>Go</Button>}
                        disabled={!ability.can('update', subject('Invoice', invoice), 'invoiceLink')}
                        onSearch={(value: string) => window.open(value, '_blank', 'noopener,noreferrer')}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Client link" name="clientInvoiceLink" className="invoice-link">
                      <Input.Search
                        enterButton={<Button>Go</Button>}
                        disabled={!ability.can('update', subject('Invoice', invoice), 'clientInvoiceLink')}
                        onSearch={(value: string) => window.open(value, '_blank', 'noopener,noreferrer')}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={4}>
                <Row gutter={16}>
                  <Col span={15}>
                    <Form.Item label="Group by phase?" name="groupByPhase" valuePropName="checked">
                      <Switch disabled={!ability.can('update', subject('Invoice', invoice), 'groupByPhase')} />
                    </Form.Item>
                  </Col>

                  <Col span={9}>
                    <Form.Item label="Is active?" name="isActive" valuePropName="checked">
                      <Switch disabled={!ability.can('update', subject('Invoice', invoice), 'isActive')} />
                    </Form.Item>
                  </Col>
                </Row>

                {ability.can('view', 'Commission') && (
                  <Form.Item label="Commission" name="commission">
                    <List
                      style={{ marginBottom: '24px' }}
                      dataSource={commission?.filter(user => user.value > 0)}
                      renderItem={userWithCommission => (
                        <List.Item>
                          <Row style={{ width: '100%' }} justify="space-between" align="middle">
                            <Col>
                              <Typography.Text>
                                {userWithCommission.userName} <Typography.Text strong>({userWithCommission.value}%)</Typography.Text>
                              </Typography.Text>
                            </Col>

                            <Col>
                              <Typography.Text strong>
                                ${((invoiceForm.getFieldValue('value') * userWithCommission.value) / 100).toFixed(2)}
                              </Typography.Text>
                            </Col>
                          </Row>
                        </List.Item>
                      )}
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>

            {ability.can('create', 'Invoice') && (
              <Form.Item shouldUpdate noStyle>
                {() =>
                  invoiceForm.getFieldValue('details').length === 0 ? (
                    <Button
                      disabled={invoice?.isActive}
                      type="dashed"
                      block
                      icon={<PlusOutlined />}
                      size="large"
                      onClick={() => setIsEditModalOpen(true)}
                    >
                      Add line items from timesheet
                    </Button>
                  ) : null
                }
              </Form.Item>
            )}
            <Row gutter={8} justify="space-between">
              <Col>
                <Form.Item shouldUpdate noStyle>
                  {() =>
                    invoiceForm.getFieldValue('details').length !== 0 ? (
                      <Form.Item label="Line items date range" name="dateRange">
                        <DatePicker.RangePicker
                          allowClear={false}
                          inputReadOnly
                          open={false}
                          disabled={!ability.can('update', subject('Invoice', invoice), 'dateRange')}
                        />
                      </Form.Item>
                    ) : null
                  }
                </Form.Item>
              </Col>
              <Col>
                {ability.can('create', 'Invoice') && (
                  <Form.Item shouldUpdate>
                    {() =>
                      invoiceForm.getFieldValue('details').length !== 0 ? (
                        <Button
                          disabled={!ability.can('update', subject('Invoice', invoice), 'dateRange')}
                          type="dashed"
                          icon={<EditOutlined />}
                          style={{ padding: '0 0.5rem' }}
                          onClick={() => setIsEditModalOpen(true)}
                        >
                          Edit line items
                        </Button>
                      ) : null
                    }
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={24} className="invoice-table">
                <Form.Item name="details">
                  <InvoiceTableInternal />
                </Form.Item>

                <Form.Item name="details">
                  <InvoiceTableInternal isClientView groupByPhase={invoice.groupByPhase} />
                </Form.Item>

                <Col>
                  {ability.can('create', 'Invoice') && (
                    <Form.Item shouldUpdate>
                      {() =>
                        invoiceForm.getFieldValue('details').length !== 0 ? (
                          <Button
                            disabled={!ability.can('update', subject('Invoice', invoice), 'dateRange')}
                            type="dashed"
                            icon={<PlusOutlined />}
                            style={{ padding: '0 0.5rem' }}
                            onClick={() => setIsAddModalOpen(true)}
                          >
                            Add line items
                          </Button>
                        ) : null
                      }
                    </Form.Item>
                  )}
                </Col>

                <Form.Item name="id" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
                <Form.Item name="markupPercentage" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
                <Form.Item name="actualCost" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
                <Form.Item name="isActive" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Space>

      <Modal
        title="Timesheet data"
        open={isEditModalOpen}
        width="1200px"
        onCancel={() => {
          setIsEditModalOpen(false);
        }}
        destroyOnClose
        footer={null}
      >
        <InvoiceCreator
          projectId={projectId}
          projectBillableRate={billableRate}
          onInvoiceCreated={data => onInvoiceEditCompleted(data)}
          editingInvoice={createdInvoice || (invoice.id ? invoice : null)}
        />
      </Modal>

      <AddLineItemModal
        modalOpen={isAddModalOpen}
        setModalOpen={setIsAddModalOpen}
        details={invoiceForm.getFieldValue('details')?.filter(detail => detail.lineItemAmount)}
        onSubmit={onLineItemAdded}
      />
    </Modal>
  );
};

export default InvoiceModal;
