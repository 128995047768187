import { useState } from 'react';
import { Button, PageHeader, Spin, Table, Tooltip, Typography } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { formatCurrency, formatDate } from '../../common/utils';
import { useCashFlowReport } from '../../dal/useCashFlowReport';

const CashFlowReport = () => {
  const [currentYear, setCurrentYear] = useState<number | null>(null);
  const { result, pagination } = useCashFlowReport(currentYear);

  const Summary = pageData => {
    var totals = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    pageData.forEach(({ payments }) => {
      for (let i = 0; i < 12; i++) {
        totals[i] += payments[i + 1].reduce((res, cur) => res + parseFloat(cur.payment), 0);
      }
    });

    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} align="right">
            Totals
          </Table.Summary.Cell>
          {totals.map((t, i) => (
            <Table.Summary.Cell index={i + 1} align="right">
              {formatCurrency(t)}
            </Table.Summary.Cell>
          ))}
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  if (!result)
    return (
      <div>
        <Spin />
      </div>
    );

  console.log(Object.values(result) as object[]);

  return (
    <div className="flex flex-col h-full">
      <PageHeader
        title="Cash Flow Report"
        subTitle={pagination?.current}
        extra={[
          <Button icon={<ArrowLeftOutlined />} onClick={() => setCurrentYear(pagination.prev)} />,
          <Button icon={<ArrowRightOutlined />} onClick={() => setCurrentYear(pagination.next)} />,
        ]}
      />

      <div className="ant-space-vertical">
        <Table
          bordered
          size="small"
          dataSource={Object.values(result) as object[]}
          summary={Summary}
          pagination={false}
          scroll={{ y: 'calc(100vh - 270px)' }}
        >
          <Table.Column
            dataIndex="projectName"
            title="Project"
            render={(_, i: any) => <Tooltip title={i.projectName}>{i.projectName}</Tooltip>}
            ellipsis
          />
          <Table.Column dataIndex={['payments', 1]} title="Jan" width={95} align="right" render={items => <Payments items={items} />} />
          <Table.Column dataIndex={['payments', 2]} title="Feb" width={95} align="right" render={items => <Payments items={items} />} />
          <Table.Column dataIndex={['payments', 3]} title="Mar" width={95} align="right" render={items => <Payments items={items} />} />
          <Table.Column dataIndex={['payments', 4]} title="Apr" width={95} align="right" render={items => <Payments items={items} />} />
          <Table.Column dataIndex={['payments', 5]} title="May" width={95} align="right" render={items => <Payments items={items} />} />
          <Table.Column dataIndex={['payments', 6]} title="Jun" width={95} align="right" render={items => <Payments items={items} />} />
          <Table.Column dataIndex={['payments', 7]} title="Jul" width={95} align="right" render={items => <Payments items={items} />} />
          <Table.Column dataIndex={['payments', 8]} title="Aug" width={95} align="right" render={items => <Payments items={items} />} />
          <Table.Column dataIndex={['payments', 9]} title="Sep" width={95} align="right" render={items => <Payments items={items} />} />
          <Table.Column dataIndex={['payments', 10]} title="Oct" width={95} align="right" render={items => <Payments items={items} />} />
          <Table.Column dataIndex={['payments', 11]} title="Nov" width={95} align="right" render={items => <Payments items={items} />} />
          <Table.Column dataIndex={['payments', 12]} title="Dec" width={95} align="right" render={items => <Payments items={items} />} />
        </Table>
      </div>
    </div>
  );
};

const Payments = ({ items }) => {
  return items.map(row => (
    <Tooltip title={formatDate(row.paymentDate)}>
      <PaymentValue row={row} />
      <br />
    </Tooltip>
  ));
};

const PaymentValue = ({ row }) => {
  if (row.paid) return <Typography.Text type="success">{formatCurrency(row.payment)}</Typography.Text>;

  if (moment(row.paymentDate).isBefore(moment())) return <Typography.Text type="danger">{formatCurrency(row.payment)}</Typography.Text>;

  return <Typography.Text>{formatCurrency(row.payment)}</Typography.Text>;
};

export default CashFlowReport;
