import { Select } from 'antd';
import { useProjects } from '../../dal';
import { Project } from '../../entities';

interface ProjectSelectorProps {
  hideClosed?: boolean;
  excludeIds?: string[];
  [key: string]: any;
}

function mapProject(p: Project) {
  return {
    value: p.id,
    label: `${p.client?.name || 'No client'} - ${p.name} - ${p.autoCode}`,
  };
}

const ProjectSelector = ({ hideClosed = false, excludeIds = [], ...props }: ProjectSelectorProps) => {
  const { projects = [] } = useProjects();

  const filteredProjects = projects.filter(p => !excludeIds.includes(p.id));

  const closedProjects = filteredProjects.filter(p => p.status === 'finished').map(mapProject);

  const openProjects = filteredProjects.filter(p => p.status === 'active' || p.status === 'draft').map(mapProject);

  const options = [{ label: 'Open', options: openProjects }, hideClosed ? undefined : { label: 'Closed', options: closedProjects }].filter(
    (group): group is { label: string; options: { value: string; label: string }[] } => Boolean(group),
  );

  return (
    <Select
      {...props}
      showSearch
      placeholder="Select project"
      options={options}
      filterOption={(input, option) => `${option?.label}`.toLowerCase().includes(input.toLowerCase())}
    />
  );
};

export default ProjectSelector;
