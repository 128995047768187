import { useState } from 'react';
import { getLastSaturday } from '../../../common/utils';
import { useApprovals } from '../../../dal/useApprovals';
import { Approval } from '../../../entities';

export function useApprovalsData({ monthlyView }: { monthlyView?: boolean } = {}): {
  approvals?: Array<Approval>;
  startDate: string;
  getPreviousWeek: () => void;
  getNextWeek: () => void;
  getPreviousMonth: () => void;
  getNextMonth: () => void;
  refresh: () => void;
  setStartDate: (day: string) => void;
} {
  const [startDate, setStartDate] = useState<string>(getLastSaturday());
  const { approvals, pagination, mutate } = useApprovals(startDate, monthlyView);

  const getPreviousWeek = () => {
    setStartDate(pagination.prev);
  };

  const getNextWeek = () => {
    setStartDate(pagination.next);
  };

  const getPreviousMonth = () => {
    setStartDate(pagination.prev);
  };
  const getNextMonth = () => {
    setStartDate(pagination.next);
  };

  const refresh = async () => {
    await mutate(null, true);
  };

  return {
    approvals,
    getPreviousWeek,
    getNextWeek,
    getPreviousMonth,
    getNextMonth,
    startDate,
    refresh,
    setStartDate,
  };
}
