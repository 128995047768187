import { Button, PageHeader, Space, Spin, Table, Tag, Switch } from 'antd';
import Title from 'antd/lib/typography/Title';
import { formatDateTime, getApprovalStatus, getLastSaturday, getFirstDayOfMonth } from '../../common/utils';
import TimesheetTabsControls from '../../components/Timesheet/TimesheetTabsControls';
import { useApprovalsData } from './hooks/useApprovalsData';
import { Approval, ApprovalStatus, ApprovalType, User } from '../../entities';
import moment from 'moment';
import { useState } from 'react';
import ApprovalDetails from '../../components/approvals/ApprovalDetails';
import axios from 'axios';
import ApprovalRejection from '../../components/approvals/ApprovalRejection';
import { CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';

const medals = ['🥇', '🥈', '🥉'];

const Approvals = () => {
  const [detailsOpen, setDetailsOpen] = useState<{ visible: boolean; userId?: number; approvalId?: string; canApprove: boolean }>({
    visible: false,
    canApprove: false,
  });
  const [rejectModal, setRejectModal] = useState<{ visible: boolean; id?: string }>({ visible: false });
  const [monthlyView, setMontlyView] = useState(false);
  const { startDate, getPreviousWeek, getNextWeek, getPreviousMonth, getNextMonth, approvals, refresh, setStartDate } = useApprovalsData({
    monthlyView,
  });

  const monthlyViewChange = (checked: boolean) => {
    setMontlyView(checked);
    setStartDate(checked ? getFirstDayOfMonth() : getLastSaturday());
  };

  const closeDetailsModal = () => {
    setDetailsOpen({ visible: false, userId: undefined, canApprove: false });
  };

  const handleApprove = async (id?: string) => {
    await axios.post(`/timesheets/approvals/${id}/approve${monthlyView ? '?monthlyView=true' : ''}`);
    closeDetailsModal();
    await refresh();
  };

  const rejectApproval = async (comment: string) => {
    await axios.post(`/timesheets/approvals/${rejectModal.id}/reject${monthlyView ? '?monthlyView=true' : ''}`, { comment });
    await refresh();
    closeDetailsModal();
    setRejectModal({ visible: false, id: undefined });
  };

  return (
    <div className="flex flex-col h-full">
      <PageHeader
        title="Approvals"
        tags={<Switch checkedChildren="Monthly" unCheckedChildren="Monthly" onChange={checked => monthlyViewChange(checked)} />}
      >
        {monthlyView ? (
          <div style={{ textAlign: 'center' }}>
            <Title level={5}>{formatDateTime(startDate, 'MMMM, YYYY')}</Title>
            <Space>
              <TimesheetTabsControls onGetPrevious={getPreviousMonth} onGetNext={getNextMonth} />
            </Space>
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Title level={5}>
              {formatDateTime(startDate, 'D MMM')} - {formatDateTime(moment(startDate).add(6, 'days').toDate(), 'D MMM')}
            </Title>
            <Space>
              <TimesheetTabsControls onGetPrevious={getPreviousWeek} onGetNext={getNextWeek} />
            </Space>
          </div>
        )}
      </PageHeader>
      <div className="ant-space-vertical">
        {approvals ? (
          <Table dataSource={approvals} size="small" rowKey={record => record.user.id} pagination={false}>
            <Table.Column
              dataIndex="user"
              title="User"
              render={(user: User, row: Approval, index) => (
                <>
                  {row?.approval?.submittedAt && medals[index]} {user.name}
                </>
              )}
            />
            <Table.Column
              dataIndex="approval"
              title="Submitted at"
              render={(approval: ApprovalType) => {
                if (getApprovalStatus(approval) === ApprovalStatus.REJECTED) {
                  return <Tag color="red">Rejected</Tag>;
                }

                if (getApprovalStatus(approval) === ApprovalStatus.APPROVED) {
                  return (
                    <Tag icon={<CheckCircleOutlined />} color="success">
                      Approved
                    </Tag>
                  );
                }

                if (getApprovalStatus(approval) === ApprovalStatus.AWAITING_REAPPROVAL) {
                  return <Tag color="orange">Ready for reapproval</Tag>;
                }

                return (
                  <>{approval?.submittedAt ? moment(approval?.submittedAt).format('lll') : <Tag icon={<ClockCircleOutlined />}>Not submited</Tag>}</>
                );
              }}
            />
            <Table.Column
              title="Options"
              render={record => (
                <>
                  {record.approval && (
                    <Button
                      type="text"
                      onClick={() =>
                        setDetailsOpen({
                          visible: true,
                          userId: record.user.id,
                          approvalId: record.approval.id,
                          canApprove:
                            getApprovalStatus(record.approval) === ApprovalStatus.AWAITING_APPROVAL ||
                            getApprovalStatus(record.approval) === ApprovalStatus.AWAITING_REAPPROVAL,
                        })
                      }
                    >
                      Details
                    </Button>
                  )}
                </>
              )}
            />
          </Table>
        ) : (
          <Spin />
        )}
        {detailsOpen.visible && detailsOpen.userId && (
          <ApprovalDetails
            startDate={startDate}
            monthlyView={monthlyView}
            userId={detailsOpen.userId}
            canApprove={detailsOpen.canApprove}
            refreshApprovals={refresh}
            onClose={() => setDetailsOpen({ visible: false, userId: undefined, canApprove: false })}
            onApprove={() => handleApprove(detailsOpen.approvalId)}
            onReject={() => setRejectModal({ visible: true, id: detailsOpen.approvalId })}
          />
        )}
        {rejectModal.visible && rejectModal.id && (
          <ApprovalRejection
            onClose={() => setRejectModal({ visible: false, id: undefined })}
            onEdit={(comment: string) => rejectApproval(comment)}
          />
        )}
      </div>
    </div>
  );
};

export default Approvals;
