import { ReactNode } from 'react';
import { FIGMA_URL, GOOGLE_DOCS_URL, GOOGLE_SHEETS_URL, PANDA_DOC_URL, XERO_URL } from './constants';
import { BillableInfo, GroupByType } from '../entities';
import { ReactComponent as IconFigma } from '../images/icons/icon-figma.svg';
import { ReactComponent as IconDocs } from '../images/icons/icon-google-docs.svg';
import { ReactComponent as IconSheets } from '../images/icons/icon-google-sheets.svg';
import { ReactComponent as IconXero } from '../images/icons/icon-xero.svg';
import { ReactComponent as IconPandadoc } from '../images/icons/icon-pandadoc.svg';
import { ReactComponent as IconFile } from '../images/icons/file-gray.svg';
import { GroupableTimesheetEntry } from '../entities/Invoice';

const iconMap: Record<string, ReactNode> = {
  [FIGMA_URL]: <IconFigma />,
  [GOOGLE_DOCS_URL]: <IconDocs />,
  [GOOGLE_SHEETS_URL]: <IconSheets />,
  [XERO_URL]: <IconXero />,
  [PANDA_DOC_URL]: <IconPandadoc />,
};

export const getIcon = (url: string): ReactNode => {
  const matchingKey = Object.keys(iconMap).find(key => url.includes(key));
  return matchingKey ? iconMap[matchingKey] : <IconFile />;
};

export const getInitials = (name: string) => {
  const words = name.trim().split(' ');
  const initials = words.map((word, index) => (index === 0 || index === words.length - 1 ? word.charAt(0).toUpperCase() : '')).join('');
  return initials;
};

export const deepMerge = (oldData, newData) => {
  for (const key in newData) {
    if (typeof newData[key] === 'object' && !Array.isArray(newData[key])) {
      if (!oldData[key]) {
        oldData[key] = {};
      }
      deepMerge(oldData[key], newData[key]);
    } else {
      oldData[key] = newData[key];
    }
  }
  return oldData;
};

export const formInvoiceFromTimesheetData = (timesheets: GroupableTimesheetEntry[], groupingOption: GroupByType) => {
  if (!groupingOption) return timesheets;

  const groupedData = timesheets.reduce(
    (acc, { category, phaseName, billableHours, billableTotal, fixedCost, fixedBillableTotal, lineItemAmount, lineItemName, ...timesheet }) => {
      const key = groupingOption === 'role' ? category : phaseName || 'No phase';

      acc[key] ??= {
        [groupingOption]: key,
        children: [],
        billableHours: 0,
        billableTotal: 0,
        billableRate: 0,
        fixedCostTotal: 0,
        fixedBillableTotal: 0,
        lineItemAmount: 0,
        lineItemName,
      };

      acc[key].children.push({ ...timesheet, category, phaseName, billableHours, billableTotal, fixedCost, fixedBillableTotal, lineItemAmount });
      acc[key].billableHours += billableHours || 0;
      acc[key].billableTotal += billableTotal || 0;
      acc[key].billableRate = Math.round(((acc[key].billableTotal || 0) / acc[key].billableHours) * 100) / 100 || 0;
      acc[key].fixedCostTotal += fixedCost;
      acc[key].fixedBillableTotal += fixedBillableTotal || 0;
      acc[key].lineItemAmount += lineItemAmount || 0;

      return acc;
    },
    {},
  );

  const formattedInvoiceFromTimesheetData = Object.values(groupedData).flatMap((group: any) => {
    const key = groupingOption === 'role' ? group.role : group.phase || 'No phase';

    const groupedItems = [
      {
        key,
        billableHours: group.billableHours,
        billableRate: group.billableRate,
        billableTotal: group.billableTotal,
        lineItemAmount: group.lineItemAmount,
        isGroup: true,
        type: groupingOption,
        lineItemName: group.lineItemName,
      },
      ...group.children.filter(({ totalMinutes, totalCost }: GroupableTimesheetEntry) => totalMinutes && totalCost > 0),
    ];

    if (group.fixedCostTotal > 0) {
      groupedItems.push(
        {
          key: `${key} (fixed cost)`,
          fixedCostTotal: group.fixedCostTotal,
          fixedBillableTotal: group.fixedBillableTotal,
          isGroup: true,
          type: groupingOption,
        },
        ...group.children.filter(({ fixedCost }: GroupableTimesheetEntry) => fixedCost > 0),
      );
    }

    return groupedItems;
  });

  return formattedInvoiceFromTimesheetData;
};

export const convertToTitleCase = (str: string) => (!str ? '' : str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase()));

export const calculateGroupSum = (customValues: Record<string, BillableInfo>, groupKey: string, type: 'hours' | 'spend' | 'fixed') => {
  return Object.entries(customValues).reduce((sum, [key, value]) => {
    const isGroupMatch = key.includes(groupKey);
    const isFixedGroupMatch = key.includes(groupKey.replace(' (fixed cost)', ''));

    if (type === 'hours' && isGroupMatch) {
      sum += value.billableHours;
    }

    if (type === 'spend' && isGroupMatch) {
      sum += value.billableHours * value.billableRate;
    }

    if (type === 'fixed' && isFixedGroupMatch && !value.billableHours && value.billableTotal) {
      sum += value.billableTotal;
    }

    return sum;
  }, 0);
};
