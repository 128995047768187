import { useCallback, useState } from 'react';
import { Input, Form, Spin, Button, Select, Space, DatePicker, message } from 'antd';
import { CreateTimesheetEntry, User } from '../../entities';
import { useProjects } from '../../dal';
import './TimesheetFormRow.css';
import HoursInput, { checkHours } from './HoursInput';
import MoneyInput from '../shared/MoneyInput';
import ProjectSelector from '../project/ProjectSelector';
import { submitTimesheet } from '../../dal/submitTimesheet';
import CategorySelector from '../project/CategorySelector';
import TaskSelector from '../project/TaskSelector';
import moment from 'moment';
import PhaseSelector from '../project/PhaseSelector';

interface Props {
  projectId?: string;
  taskId?: string;
  date?: string;
  user?: User;
  refreshTimesheets?: () => void;
  vertical?: boolean;
  onSubmitAction?: () => void;
}

const TimesheetFormRow = ({ user, projectId, taskId, date, refreshTimesheets, vertical = false, onSubmitAction }: Props) => {
  const { isLoading, projects } = useProjects();
  const [form] = Form.useForm();
  const [spentInput, setSpentInput] = useState('Hours');
  const [selectedProject, setSelectedProject] = useState(projectId || null);
  const [selectedPhase, setSelectedPhase] = useState(null);

  const handleFormValuesChange = useCallback(changedValues => {
    const fieldName = Object.keys(changedValues)[0];

    if (fieldName === 'project') {
      const value = changedValues[fieldName];
      setSelectedProject(value);
      setSelectedPhase(null);
    }

    if (fieldName === 'phase') {
      const value = changedValues[fieldName];
      setSelectedPhase(value);
    }
  }, []);
  const close = useCallback(() => {
    form.resetFields();
    onSubmitAction && onSubmitAction();
  }, [form, onSubmitAction]);

  const submit = useCallback(
    async (values: CreateTimesheetEntry) => {
      const defaultTask = taskId ? { task: taskId } : {};
      const defaultUser = user ? { user: user.id } : {};
      const defaultEntryDate = { entryDate: values.entryDate || moment(date).format('YYYY-MM-DD') };

      const data: any = {
        ...values,
        ...defaultEntryDate,
        ...defaultTask,
        ...defaultUser,
      };

      delete data.date;
      delete data.phase;

      await submitTimesheet(data)
        .then(() => {
          refreshTimesheets && refreshTimesheets();
          close();
        })
        .catch(err => message.error(err.response.data?.message));
    },
    [close, date, refreshTimesheets, taskId, user],
  );

  const onOk = useCallback(() => {
    if (!isLoading && projects.length > 0) {
      form
        .validateFields()
        .then(values => {
          submit({
            ...values,
            isCommission: spentInput === 'Commission',
          });
        })
        .catch(info => {
          console.log('Validate Failed:', info);
        });
    } else {
      close();
    }
  }, [close, form, isLoading, projects?.length, spentInput, submit]);

  const { Option } = Select;
  const customRateOrCost = user && (user.allowUrgentRate || user.allowVariableRate || user.allowFixedCost || user.roles.indexOf('sales') > -1);

  return (
    <div className="timesheet-row-form-container">
      {isLoading && <Spin />}
      {!isLoading && projects && projects.length === 0 && <p>You should be in at least one project.</p>}
      {!isLoading && projects && projects.length > 0 && (
        <Form
          form={form}
          onFinish={onOk}
          autoComplete="off"
          initialValues={{ project: projectId ? projectId : null }}
          layout="inline"
          className="timesheet-row-form"
          onValuesChange={handleFormValuesChange}
        >
          <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }} className={vertical ? 'timesheet-form-vertical' : ''}>
            <div className={projectId ? '' : 'timesheet-form-row-project'} style={projectId ? undefined : { width: '350px' }}>
              {!projectId && <h4>Project, phase and task</h4>}
              <Space direction="vertical">
                <Form.Item name="project" rules={[{ required: true, message: 'Project is required' }]} hidden={!!projectId}>
                  <ProjectSelector className="project-select" disabled={!!projectId} style={{ width: '330px' }} />
                </Form.Item>

                <Form.Item name="phase">
                  <PhaseSelector projectid={selectedProject} />
                </Form.Item>

                {!taskId && (
                  <Form.Item name="task" rules={[{ required: true, message: 'Task is required' }]}>
                    <TaskSelector projectid={selectedProject} phase={selectedPhase} />
                  </Form.Item>
                )}
              </Space>
            </div>

            <div className={projectId ? 'timesheet-form-row-project--narrow' : 'timesheet-form-row-project'}>
              <h4>Role</h4>
              <Form.Item name="category" rules={[{ required: true, message: 'Category is required' }]}>
                <CategorySelector />
              </Form.Item>
            </div>

            <div className="timesheet-form-row-time">
              <h4>Time spent</h4>
              <div className="timesheet-form-row-time__container">
                <Input.Group compact>
                  {customRateOrCost && (
                    <Select defaultValue="Hours" style={{ width: '100px' }} onChange={val => setSpentInput(val)}>
                      <Option value="Hours">Hours</Option>
                      {user.allowUrgentRate && <Option value="Urgent">Urgent hours</Option>}
                      {user.allowVariableRate && <Option value="Custom">Custom rate</Option>}
                      {user.allowFixedCost && <Option value="Fixed">Fixed cost</Option>}
                      {user.roles.indexOf('sales') > -1 && <Option value="Commission">Commission</Option>}
                    </Select>
                  )}
                  {(spentInput === 'Hours' || spentInput === 'Urgent' || spentInput === 'Custom') && (
                    <>
                      {spentInput === 'Urgent' && (
                        <Form.Item name="customRate" initialValue={user?.urgentRateValue || 0} preserve={false} style={{ marginRight: 0 }}>
                          <Input type="hidden" />
                        </Form.Item>
                      )}
                      {spentInput === 'Custom' && (
                        <Form.Item
                          name="customRate"
                          preserve={false}
                          rules={[{ transform: val => parseFloat(val), required: true, type: 'number', min: 0, message: 'Custom rate is required' }]}
                        >
                          <MoneyInput placeholder="Rate" style={{ width: '80px' }} />
                        </Form.Item>
                      )}
                      <Form.Item name="hours" rules={[{ required: true, message: 'Time spent is required', validator: checkHours }]}>
                        <HoursInput placeholder="Time spent" style={{ width: spentInput === 'Custom' ? '80px' : '120px' }} />
                      </Form.Item>
                      {/* <Tooltip
                      placement="top"
                      title="You can write your time in either decimal formal (example 1.5) or in time format (1:30). Our system recognizes both formats as the same thing."
                    >
                      <InfoCircleOutlined style={{ fontSize: '20px', margin: '6px 0 0 6px' }} />
                    </Tooltip> */}
                    </>
                  )}
                  {(spentInput === 'Fixed' || spentInput === 'Commission') && (
                    <Form.Item
                      name="fixedCost"
                      rules={[{ transform: val => parseFloat(val), required: true, type: 'number', min: 0, message: 'Fixed Cost is required' }]}
                    >
                      <MoneyInput style={{ width: '128px' }} />
                    </Form.Item>
                  )}
                </Input.Group>
              </div>
            </div>
            <div className="timesheet-form-row-description">
              <h4>Description</h4>
              <Form.Item name="description">
                <Input placeholder="Description" />
              </Form.Item>
            </div>
            <div className="timesheet-form-row-description">
              <h4>When</h4>
              <Form.Item name="entryDate">
                <DatePicker defaultValue={moment(date)} />
              </Form.Item>
            </div>
            <div className="timesheet-form-row-button">
              <Button onClick={onOk} type="primary">
                Add
              </Button>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

export default TimesheetFormRow;
