import { useCallback, useMemo } from 'react';
import { Select, Row, Col, Typography } from 'antd';
import { ApprovalsPermissions } from '../../common/usePermissions/permissions';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { useSession } from '../../dal';
import { useProjectTasks } from '../../dal/useProjectTasks';
import { Task, TaskPhase } from '../../entities';

const TaskSelector = (props: any) => {
  const { session } = useSession();
  const { hasPermission } = usePermissions();
  const { phases = [], unassigned = [], isLoading } = useProjectTasks(props.projectid);

  const filterTasks = useCallback(
    (tasks: Task[]) =>
      hasPermission(ApprovalsPermissions.APPROVALS_WRITE) ? tasks : tasks.filter(task => task.assignees?.some(a => a.id === session?.id)),
    [hasPermission, session?.id],
  );

  const generateOptions = useCallback(
    (phase?: TaskPhase | { name: string; tasks: Task[] }) => {
      if (!phase) {
        return;
      }

      return {
        label: phase.name,
        options: filterTasks(phase.tasks || []).map(task => ({
          value: task.id,
          label: task.name,
          data: task,
        })),
      };
    },
    [filterTasks],
  );

  const options = useMemo(() => {
    if (props.phase) {
      const selectedPhase =
        props.phase === 'Unassigned' ? { name: 'Unassigned', tasks: unassigned } : phases.find(phase => phase.name === props.phase);

      return [generateOptions(selectedPhase)];
    }

    const allPhasesOptions = phases.map(generateOptions);
    const unassignedOptions = generateOptions({ name: 'Unassigned', tasks: unassigned });

    return [...allPhasesOptions, unassignedOptions].filter(group => group?.options?.length && group?.options?.length > 0);
  }, [generateOptions, phases, props.phase, unassigned]);

  const getLabel = useCallback((task: Task) => {
    const remainingTime = task.estimate != null ? task.estimate - task.timeSpent : null;
    return (
      <Row justify="space-between">
        <Col>{task.name}</Col>
        {remainingTime != null && (
          <Col>
            <Typography.Text type={remainingTime >= 0 ? 'secondary' : 'danger'}>
              {Math.abs(remainingTime).toFixed(1)}h {remainingTime >= 0 ? 'left' : 'over'}
            </Typography.Text>
          </Col>
        )}
      </Row>
    );
  }, []);

  if (!props.projectid && isLoading) {
    return <>Project not selected</>;
  }

  if (!isLoading && options.length === 0) {
    return (
      <>
        No tasks in this <a href={`/projects/${props.projectid}`}>project</a>
      </>
    );
  }

  return (
    <Select
      {...props}
      showSearch
      allowClear
      placeholder="Select task"
      filterOption={(input, option) => option?.label?.toString().toLowerCase().includes(input.toLowerCase())}
      optionLabelProp="label"
    >
      {options.map(group => (
        <Select.OptGroup key={group?.label} label={group?.label}>
          {group?.options.map(option => (
            <Select.Option key={option.value} value={option.value} label={option.label}>
              {getLabel(option.data)}
            </Select.Option>
          ))}
        </Select.OptGroup>
      ))}
    </Select>
  );
};

export default TaskSelector;
