import { Select } from 'antd';
import { useSession } from '../../dal';

const CategorySelector = (props: any) => {
  const { session } = useSession();

  if (!session)
    return null;

  return (
    <Select placeholder="Select category" {...props}>
      {(props?.user ?? session).positions.map(category => (
        <Select.Option key={category} value={category}>
          {category}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CategorySelector;
