import { useState } from 'react';
import { Modal, Input, Form, Spin, DatePicker, Button, Popconfirm, Select, message } from 'antd';
import { CreateTimesheetEntry, User } from '../../entities';
import { useProjects } from '../../dal';
import axios from 'axios';
import { useEffect } from 'react';
import { useTimesheet } from '../../dal/useTimesheet';
import moment from 'moment';
import HoursInput, { checkHours } from './HoursInput';
import MoneyInput from '../shared/MoneyInput';
import ProjectSelector from '../project/ProjectSelector';
import { submitTimesheet } from '../../dal/submitTimesheet';
import CategorySelector from '../project/CategorySelector';
import { useCookies } from 'react-cookie';
import TaskSelector from '../project/TaskSelector';
import UserSelector from '../users/UserSelector';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import { ApprovalsPermissions } from '../../common/usePermissions/permissions';

const { Option } = Select;

interface Props {
  user?: User;
  modalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
  entryId: string;
  setEntryId: (entryId: string | null) => void;
  projectId?: string;
  defaultDate?: string;
  refreshTimesheets?: () => void;
}

const TimesheetForm = ({ user, modalOpen, setModalOpen, entryId, setEntryId, projectId, defaultDate, refreshTimesheets }: Props) => {
  const { projects } = useProjects();
  const { hasPermission } = usePermissions();
  const { timesheet } = useTimesheet(entryId || null);
  const [form] = Form.useForm();
  const [spentInput, setSpentInput] = useState('Hours');
  const [selectedProject, setSelectedProject] = useState(projectId || null);

  const handleFormValuesChange = changedValues => {
    const fieldName = Object.keys(changedValues)[0];

    if (fieldName === 'project') {
      const value = changedValues[fieldName];
      setSelectedProject(value);
      form.setFieldsValue({ task: null });
    }
  };

  useEffect(() => {
    if (timesheet && timesheet.project) {
      setSelectedProject(timesheet.project.id);
    }
    if (entryId && timesheet) {
      const spentType = timesheet.fixedCost
        ? 'Fixed'
        : timesheet.customRate
          ? timesheet.customRate === user?.urgentRateValue
            ? 'Urgent'
            : 'Custom'
          : 'Hours';
      setSpentInput(spentType);
      form.setFieldsValue({
        project: timesheet.project ? timesheet.project.id : null,
        task: timesheet.task ? timesheet.task.id : null,
        user: timesheet.user ? timesheet.user.id : null,
        entryDate: moment(timesheet.entryDate),
        hours: timesheet.hours,
        fixedCost: timesheet.fixedCost,
        category: timesheet.category,
        description: timesheet.description,
        customRate: timesheet.customRate || user?.urgentRateValue,
        spentType: spentType,
      });
    }
  }, [entryId, timesheet, form, user]);

  const onOk = () => {
    if (projects && projects.length > 0) {
      form
        .validateFields()
        .then(values => {
          form.resetFields();
          submit(values);
        })
        .catch(info => {
          console.log('Validate Failed:', info);
        });
    } else {
      close();
    }
  };

  const close = () => {
    setModalOpen(false);
    setEntryId && setEntryId(null);
    form.resetFields();
  };

  const submit = async (values: CreateTimesheetEntry) => {
    await submitTimesheet(values, entryId)
      .then(() => {
        refreshTimesheets && refreshTimesheets();
        close();
      })
      .catch(err => message.error(err.response.data?.message));
  };

  const [cookies, setCookies, removeCookie] = useCookies(['yesterday_submition']);
  const onDelete = async () => {
    removeCookie('yesterday_submition', {});
    await axios.delete(`/timesheets/${entryId}`);
    refreshTimesheets && refreshTimesheets();
    console.log(0 > 1 ? `${cookies} ${setCookies}` : null);
    close();
  };

  return (
    <Modal
      title="Edit entry"
      open={modalOpen}
      onOk={onOk}
      onCancel={close}
      footer={[
        <Popconfirm title="Are you sure?" onConfirm={onDelete}>
          <Button key="delete" type="link" danger>
            Delete
          </Button>
        </Popconfirm>,
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onOk}>
          Ok
        </Button>,
      ]}
    >
      {(!timesheet || !projects) && <Spin />}
      {timesheet && timesheet.id && projects && projects.length === 0 && <p>You should be in at least one project.</p>}
      {timesheet && timesheet.id && projects && projects.length > 0 && (
        <Form
          form={form}
          onFinish={submit}
          autoComplete="off"
          initialValues={{
            user: timesheet.user ? timesheet.user.id : null,
            project: projectId ? projectId : null,
            task: timesheet.task ? timesheet.task.id : null,
            entryDate: moment(defaultDate) || undefined,
          }}
          layout="vertical"
          onValuesChange={handleFormValuesChange}
        >
          {hasPermission(ApprovalsPermissions.APPROVALS_WRITE) && (
            <Form.Item label="User" name="user" rules={[{ required: true, message: 'User is required' }]}>
              <UserSelector />
            </Form.Item>
          )}

          <Form.Item label="Project" name="project" rules={[{ required: true, message: 'Project is required' }]}>
            <ProjectSelector disabled={!!projectId} />
          </Form.Item>

          <Form.Item label="Task" name="task">
            <TaskSelector projectid={selectedProject} />
          </Form.Item>

          <Form.Item label="Date" name="entryDate" rules={[{ required: true, message: 'Date is required' }]}>
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item label="Spent" required style={{ marginBottom: 0 }}>
            <Input.Group compact>
              {user && (user.allowUrgentRate || user.allowVariableRate || user.allowFixedCost) && (
                <Form.Item name="spentType">
                  <Select style={{ width: '128px' }} onChange={val => setSpentInput(val)}>
                    <Option value="Hours">Hours</Option>
                    {user.allowUrgentRate && <Option value="Urgent">Urgent hours</Option>}
                    {user.allowVariableRate && <Option value="Custom">Custom rate</Option>}
                    {user.allowFixedCost && <Option value="Fixed">Fixed cost</Option>}
                  </Select>
                </Form.Item>
              )}
              {(spentInput === 'Hours' || spentInput === 'Urgent' || spentInput === 'Custom') && (
                <>
                  {spentInput === 'Urgent' && (
                    <Form.Item name="customRate" initialValue={user?.urgentRateValue || 0} style={{ marginRight: 0 }}>
                      <Input type="hidden" />
                    </Form.Item>
                  )}
                  {spentInput === 'Custom' && (
                    <Form.Item
                      name="customRate"
                      preserve={false}
                      rules={[{ transform: val => parseFloat(val), required: true, type: 'number', min: 0, message: 'Custom rate is required' }]}
                    >
                      <MoneyInput placeholder="Rate" style={{ width: '90px' }} />
                    </Form.Item>
                  )}
                  <Form.Item
                    name="hours"
                    rules={[{ required: true, message: 'Time spent is required', validator: checkHours }]}
                    style={{ width: spentInput === 'Custom' ? 'calc(100% - 218px)' : 'calc(100% - 128px)' }}
                  >
                    <HoursInput style={{ width: '100%' }} />
                  </Form.Item>
                </>
              )}
              {spentInput === 'Fixed' && (
                <Form.Item
                  name="fixedCost"
                  rules={[{ transform: val => parseFloat(val), required: true, type: 'number', min: 0, message: 'Fixed Cost is required' }]}
                  style={{ width: 'calc(100% - 128px)' }}
                >
                  <MoneyInput style={{ width: '100%' }} />
                </Form.Item>
              )}
            </Input.Group>
          </Form.Item>

          <Form.Item label="Category / Role" name="category" rules={[{ required: true, message: 'Category is required' }]}>
            <CategorySelector user={timesheet.user} />
          </Form.Item>

          <Form.Item label="Description" name="description">
            <Input />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default TimesheetForm;
