import { Button, Form, Input, Divider, Popconfirm, Row, Col, Modal } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import MoneyInput from '../shared/MoneyInput';
import PhaseSelector from './PhaseSelector';
import { useParams } from 'react-router-dom';
import RoleSelector from './RoleSelector';

interface DataType {
  key: string;
  category: string;
  phaseName: string;
  lineItemName: string;
  lineItemAmount: number;
}

type Props = {
  modalOpen: boolean;
  setModalOpen: (value: React.SetStateAction<boolean>) => void;
  details: DataType[];
  onSubmit: (details: DataType[]) => void;
};

const AddLineItemModal = ({ modalOpen, setModalOpen, details = [], onSubmit }: Props) => {
  const [form] = Form.useForm();
  const { id } = useParams();

  const onFinish = (values: { items: DataType[] }) => {
    const lineItems = values.items.map(item => ({
      ...item,
      key: `${item.lineItemName}_${item.lineItemAmount}_${item.category}_${item.phaseName}_lineItem`,
    }));
    onSubmit(lineItems);
    setModalOpen(false);
  };

  return (
    <Modal title="Add Line Items" open={modalOpen} onOk={() => form.submit()} onCancel={() => setModalOpen(false)} width={1000}>
      <Form form={form} onFinish={onFinish} autoComplete="off" initialValues={{ items: details }}>
        <Form.List name="items">
          {(fields, { add, remove }) => (
            <>
              <Row>
                <Col span={5}>Name</Col>
                <Col span={5}>Role</Col>
                <Col span={5}>Phase</Col>
                <Col span={5}>Amount</Col>
                <Col span={4}></Col>
              </Row>
              <Divider />
              {fields.map(field => (
                <Row key={field.key} gutter={16}>
                  <Col span={5}>
                    <Form.Item {...field} name={[field.name, 'lineItemName']} rules={[{ required: true, message: 'Missing name' }]}>
                      <Input placeholder="Name" />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item {...field} name={[field.name, 'category']}>
                      <RoleSelector />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item {...field} name={[field.name, 'phaseName']}>
                      <PhaseSelector projectid={id} />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item {...field} name={[field.name, 'lineItemAmount']}>
                      <MoneyInput />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Popconfirm title="Are you sure to delete this item?" onConfirm={() => remove(field.name)} okText="Yes" cancelText="No">
                      <Button type="text" icon={<MinusCircleOutlined />} />
                    </Popconfirm>
                  </Col>
                </Row>
              ))}
              <Row>
                <Col span={24}>
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add Line Item
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default AddLineItemModal;
