import { useCallback, useEffect, useState } from 'react';
import { Button, Col, DatePicker, Input, message, Radio, Row, Space, Table, Typography } from 'antd';
import moment from 'moment';
import { useImmer } from 'use-immer';
import { TimesheetSummary, useProjectsExtendedTimesheetSummary } from '../../dal';
import { displayDurationFromMinutes, formatCurrency, nullableDataSorter } from '../../common/utils';
import MoneyInput from '../shared/MoneyInput';
import HoursInput from '../Timesheet/HoursInput';
import { calculateGroupSum, formInvoiceFromTimesheetData } from '../../utils';
import { BillableInfo, GroupByType } from '../../entities';
import { Invoice, RangeValueType } from '../../entities/Invoice';

type Props = {
  projectId: string;
  projectBillableRate: number;
  onInvoiceCreated: (data: any) => void;
  editingInvoice?: Invoice | null;
};

const InvoiceCreator = ({ projectId, projectBillableRate, onInvoiceCreated, editingInvoice }: Props) => {
  const [dateRange, setDateRange] = useState<RangeValueType>(editingInvoice?.dateRange);
  const { timesheets } = useProjectsExtendedTimesheetSummary(projectId, dateRange);
  const [customValues, setCustomValues] = useImmer<Record<string, BillableInfo>>({});
  const [globalBillableRate, setGlobalBillableRate] = useState<number>(projectBillableRate);
  const [groupingOption, setGroupingOption] = useState<GroupByType>('');
  const [prevDateRange, setPrevDateRange] = useState<RangeValueType>(dateRange);
  const [isEditingInvoiceModalAlreadyOpen, setIsEditingInvoiceModalAlreadyOpen] = useState(false);
  const [isEditInvoiceDateRangeChanged, setIsEditInvoiceDateRangeChanged] = useState(false);

  const updateCustomValues = useCallback(
    (items: any[]) => {
      return items.reduce((acc, t) => {
        const billableHours = t.totalMinutes / 60;
        const billableRate = Math.round(globalBillableRate * 100) / 100;
        const billableTotal = billableHours * billableRate;

        acc[t.key] = {
          billableHours: t.billableHours !== undefined ? t.billableHours : billableHours,
          billableRate: t.billableRate || billableRate,
          billableTotal: t.isCommission
            ? 0
            : t.fixedBillableTotal || t.fixedCost || (t.billableTotal !== undefined ? t.billableTotal : billableTotal),
        };

        return acc;
      }, {});
    },
    [globalBillableRate],
  );

  useEffect(() => {
    if (prevDateRange !== dateRange && timesheets.length !== 0) {
      setPrevDateRange(dateRange);
      setCustomValues(updateCustomValues(timesheets));
    }

    if (editingInvoice && !isEditingInvoiceModalAlreadyOpen) {
      setIsEditingInvoiceModalAlreadyOpen(true);
      setCustomValues(updateCustomValues(editingInvoice.details));
    }

    if (editingInvoice && prevDateRange !== dateRange && timesheets.length !== 0) {
      setPrevDateRange(dateRange);
      setIsEditInvoiceDateRangeChanged(true);
      setCustomValues(updateCustomValues(timesheets));
    }
  }, [
    customValues,
    dateRange,
    editingInvoice,
    globalBillableRate,
    isEditingInvoiceModalAlreadyOpen,
    prevDateRange,
    setCustomValues,
    timesheets,
    updateCustomValues,
  ]);

  const updateCustomValue = useCallback(
    (key: string, field: string, value: any) => {
      setCustomValues(draft => {
        draft[key] ??= { billableHours: 0, billableRate: globalBillableRate, billableTotal: 0 };
        draft[key][field] = value;

        if (!key.endsWith('fixedCost') && !key.endsWith('commission')) {
          draft[key]['billableTotal'] = draft[key]['billableHours'] * draft[key]['billableRate'];
        }
      });
    },
    [globalBillableRate, setCustomValues],
  );

  const handleGlobalBillableRateChange = useCallback(
    value => {
      setGlobalBillableRate(value);

      setCustomValues(draft => {
        Object.keys(draft).forEach(key => {
          draft[key]['billableRate'] = value;

          if (!key.endsWith('fixedCost') && !key.endsWith('commission')) {
            draft[key]['billableTotal'] = draft[key]['billableHours'] * draft[key]['billableRate'];
          }
        });
      });
    },
    [setCustomValues],
  );

  const submitInvoice = useCallback(() => {
    if (!dateRange) {
      message.error('No date range added');
      return;
    }

    const initialSummary = {
      summaryBillableHourlySpent: 0,
      summaryTotalMinutes: 0,
      summaryBillableHours: 0,
      summaryHourlySpent: 0,
      summaryFixedSpent: 0,
      summaryBillableFixedSpent: 0,
    };

    const calculateSummary = (items: any[]) =>
      items.reduce(
        (acc, { key, totalMinutes, totalCost, fixedCost, isCommission }) => {
          const { billableHours, billableTotal } = customValues[key];

          acc.summaryBillableHourlySpent += billableHours ? billableTotal : 0;
          acc.summaryTotalMinutes += +totalMinutes || 0;
          acc.summaryBillableHours += billableHours || 0;
          acc.summaryHourlySpent += fixedCost ? 0 : totalCost;
          acc.summaryFixedSpent += isCommission ? 0 : fixedCost;
          acc.summaryBillableFixedSpent += !billableHours ? billableTotal : 0;
          return acc;
        },
        { ...initialSummary },
      );

    const summary = editingInvoice && !isEditInvoiceDateRangeChanged ? calculateSummary(editingInvoice.details) : calculateSummary(timesheets);

    const {
      summaryBillableHourlySpent,
      summaryTotalMinutes,
      summaryBillableHours,
      summaryHourlySpent,
      summaryFixedSpent,
      summaryBillableFixedSpent,
    } = summary;

    const details = (editingInvoice && !isEditInvoiceDateRangeChanged ? editingInvoice.details : timesheets).map(t => ({
      ...t,
      role: t.category,
      phaseName: t.phaseName || '',
      billableHours: customValues[t.key].billableHours,
      billableRate: customValues[t.key].billableRate,
      billableTotal: customValues[t.key].billableHours ? customValues[t.key].billableTotal || 0 : 0,
      fixedBillableTotal: !customValues[t.key].billableHours ? customValues[t.key].billableTotal || 0 : 0,
    }));

    const actualCost = summaryHourlySpent + summaryFixedSpent;
    const actualValue = summaryBillableHourlySpent + summaryBillableFixedSpent;

    const invoiceCreated = {
      dateRange,
      value: Math.round(actualValue * 100) / 100,
      actualCost: Math.round(actualCost * 100) / 100,
      markupPercentage: ((1 - actualCost / actualValue) * 100).toFixed(2),
      summaryBillableHours,
      summaryBillableHourlySpent,
      summaryHourlySpent,
      summaryFixedSpent,
      summaryTotalMinutes,
      summaryBillableFixedSpent,
      details,
    };

    onInvoiceCreated(invoiceCreated);
  }, [customValues, dateRange, isEditInvoiceDateRangeChanged, editingInvoice, onInvoiceCreated, timesheets]);

  const summary = useCallback(
    pageData => {
      let summaryBillableHourlySpent = 0;
      let summaryTotalMinutes = 0;
      let summaryBillableHours = 0;
      let summaryHourlySpent = 0;
      let summaryFixedSpent = 0;
      let summaryBillableFixedSpent = 0;

      pageData
        .filter(pd => !pd.isGroup)
        .forEach(({ key, totalMinutes, totalCost, fixedCost, isCommission }) => {
          summaryTotalMinutes += +totalMinutes;
          summaryHourlySpent += fixedCost ? 0 : +totalCost || 0;
          summaryFixedSpent += isCommission ? 0 : +fixedCost || 0;
          summaryBillableHours += customValues[key]?.billableHours;
          summaryBillableHourlySpent += customValues[key]?.billableHours ? customValues[key]?.billableTotal || 0 : 0;
          summaryBillableFixedSpent += !customValues[key]?.billableHours ? customValues[key]?.billableTotal || 0 : 0;
        });

      return (
        <>
          <Table.Summary.Row className="role-group-row">
            <Table.Summary.Cell index={0} colSpan={groupingOption ? 3 : 4}></Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="right">
              {displayDurationFromMinutes(summaryTotalMinutes)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2} align="right">
              {formatCurrency(summaryHourlySpent + summaryFixedSpent)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3}>
              <Input value={displayDurationFromMinutes(Math.round(summaryBillableHours * 60))} bordered={false} readOnly={true} />
            </Table.Summary.Cell>
            <Table.Summary.Cell index={4}></Table.Summary.Cell>
            <Table.Summary.Cell index={5} align="right">
              {formatCurrency(summaryBillableHourlySpent + summaryBillableFixedSpent)}
            </Table.Summary.Cell>
          </Table.Summary.Row>
          <Table.Summary.Row className="role-group-row">
            <Table.Summary.Cell index={0} colSpan={groupingOption ? 7 : 8} align="right">
              Markup
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="right">
              {summaryBillableHourlySpent > 0
                ? ((1 - (summaryHourlySpent + summaryFixedSpent) / (summaryBillableHourlySpent + summaryBillableFixedSpent)) * 100).toFixed(2) + '%'
                : ''}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        </>
      );
    },
    [customValues, groupingOption],
  );

  const dataSource = formInvoiceFromTimesheetData(
    editingInvoice && !isEditInvoiceDateRangeChanged ? editingInvoice.details : timesheets,
    groupingOption,
  );

  const rowClassName = record => {
    return record.isGroup ? 'role-group-row' : '';
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row justify="space-between">
        <DatePicker.RangePicker
          value={dateRange}
          onChange={values => {
            setDateRange(values);

            if (!values) {
              setCustomValues({});
            }
          }}
          renderExtraFooter={() => (
            <Row justify="space-between">
              <Col>
                <Button type="link" onClick={() => setDateRange([moment().startOf('month'), moment().endOf('month')])}>
                  This month
                </Button>
                <Button
                  type="link"
                  onClick={() => setDateRange([moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')])}
                >
                  Previous month
                </Button>
                <Button type="link" onClick={() => setDateRange([moment().subtract(30, 'days'), moment()])}>
                  Last 30 days
                </Button>
                <Button type="link" onClick={() => setDateRange([moment().startOf('year'), moment().endOf('year')])}>
                  This year
                </Button>
              </Col>
              <Col>
                <Button
                  type="link"
                  onClick={() => {
                    setDateRange(null);
                    setCustomValues({});
                  }}
                >
                  Clear
                </Button>
              </Col>
            </Row>
          )}
        />

        <Radio.Group
          options={[
            { label: 'Ungrouped', value: '' },
            { label: 'Group by role', value: 'role' },
            { label: 'Group by phase', value: 'phase' },
          ]}
          onChange={e => setGroupingOption(e.target.value)}
          value={groupingOption}
          optionType="button"
        />
      </Row>

      <Table dataSource={dataSource} pagination={false} size="small" summary={summary} rowClassName={rowClassName}>
        <Table.Column
          title="Resource"
          dataIndex="userName"
          render={(value, { isGroup, key }: TimesheetSummary) => (isGroup ? key : value)}
          sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.userName, b.userName))}
        />
        <Table.Column
          title={groupingOption === 'role' ? 'Phase' : 'Role'}
          dataIndex={groupingOption === 'role' ? 'phaseName' : 'category'}
          sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.category, b.category))}
        />
        {!groupingOption && (
          <Table.Column
            title="Phase"
            dataIndex="phaseName"
            sorter={
              !groupingOption &&
              ((a: TimesheetSummary, b: TimesheetSummary) => {
                return nullableDataSorter(a.phaseName, b.phaseName);
              })
            }
          />
        )}
        <Table.Column
          title="Hourly rate"
          width="100px"
          dataIndex="ratePerHour"
          render={(value, { isGroup, fixedCost }: TimesheetSummary) => !isGroup && !fixedCost && formatCurrency(value)}
          sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.ratePerHour, b.ratePerHour))}
          align="right"
        />
        <Table.Column
          title="Real hours"
          width="100px"
          dataIndex="totalMinutes"
          render={(value, { isGroup }: TimesheetSummary) => !isGroup && displayDurationFromMinutes(value)}
          sorter={!groupingOption && ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.totalMinutes, b.totalMinutes))}
          align="right"
        />
        <Table.Column
          title="Real spent"
          width="100px"
          dataIndex="totalCost"
          render={(value, { isGroup, fixedCost, isCommission }: TimesheetSummary) =>
            !isGroup && isCommission ? (
              <Typography.Text delete> {formatCurrency(value || fixedCost)}</Typography.Text>
            ) : (
              formatCurrency(value || fixedCost)
            )
          }
          sorter={
            !groupingOption &&
            ((a: TimesheetSummary, b: TimesheetSummary) => nullableDataSorter(a.totalCost || a.fixedCost, b.totalCost || b.fixedCost))
          }
          align="right"
        />
        <Table.Column
          title="Billable hours"
          width="100px"
          dataIndex="key"
          align="right"
          render={(value, { isGroup, fixedCostTotal, fixedCost }: TimesheetSummary) =>
            isGroup ? (
              <HoursInput
                readOnly
                value={!fixedCostTotal ? calculateGroupSum(customValues, value, 'hours') : ''}
                onChange={(_, number) => updateCustomValue(value, 'billableHours', number)}
              />
            ) : (
              <HoursInput
                readOnly={!!fixedCost}
                value={!fixedCost ? customValues[value]?.billableHours : ''}
                onChange={(_, number) => updateCustomValue(value, 'billableHours', number)}
              />
            )
          }
        />
        <Table.Column
          title={
            <div>
              <div>Billable rate</div>

              <MoneyInput value={globalBillableRate} onChange={handleGlobalBillableRateChange} />
            </div>
          }
          width="100px"
          dataIndex="key"
          align="right"
          render={(value, { isGroup, fixedCost, totalCost, isCommission }: TimesheetSummary) =>
            totalCost && !fixedCost ? (
              <MoneyInput value={customValues[value]?.billableRate} onChange={customValue => updateCustomValue(value, 'billableRate', customValue)} />
            ) : (
              !isGroup && fixedCost && <Typography.Text italic>{isCommission ? 'Commission' : 'Fixed cost'}</Typography.Text>
            )
          }
        />
        <Table.Column
          title="Billable spend"
          width="100px"
          dataIndex="key"
          render={(value, { isGroup, fixedCost, totalCost, fixedCostTotal }: TimesheetSummary) =>
            fixedCost ? (
              <MoneyInput
                value={customValues[value]?.billableTotal}
                onChange={customValue => updateCustomValue(value, 'billableTotal', customValue)}
              />
            ) : isGroup && fixedCostTotal ? (
              formatCurrency(calculateGroupSum(customValues, value, 'fixed'))
            ) : isGroup && !fixedCostTotal ? (
              formatCurrency(calculateGroupSum(customValues, value, 'spend'))
            ) : (
              formatCurrency(totalCost ? customValues[value]?.billableHours * customValues[value]?.billableRate : fixedCost)
            )
          }
          align="right"
        />
      </Table>
      <Button type="primary" style={{ width: '100%' }} onClick={submitInvoice}>
        {`${editingInvoice ? 'Save edited' : 'Create'} invoice`}
      </Button>
    </Space>
  );
};

export default InvoiceCreator;
