import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import moment, { Moment } from 'moment';
import logo from '../../images/logo-black.jpg';
import { displayHoursFromNumber, formatCurrency } from '../../common/utils';
import { formInvoiceFromTimesheetData } from '../../utils';
import { datePickerFormat } from '../../constants';
import { TimesheetSummary } from '../../dal';
import { Invoice } from '../../entities/Invoice';
import { ORDERED_KEYS } from '../../utils/constants';

type ProjectInvoiceProps = {
  invoice: Invoice;
  project: {
    projectCode: string;
    projectName: string;
  };
};

const styles = StyleSheet.create({
  page: { padding: 24, fontSize: 12 },
  section: { marginBottom: 24 },
  header: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 24 },
  logo: { height: 50 },
  title: { fontSize: 12, marginBottom: 12 },
  invoiceNumber: { fontSize: 18 },
  billingDetails: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 12 },
  tableHeader: { flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#000', paddingBottom: 5, marginBottom: 5 },
  tableRow: { flexDirection: 'row', paddingBottom: 5, marginBottom: 5 },
  tableSummary: { flexDirection: 'row', borderTopWidth: 1, borderBottomWidth: 1, marginTop: 20, marginBottom: 25, paddingVertical: 5 },
  column: { width: '25%', textAlign: 'left', paddingRight: 10 },
  textRight: { textAlign: 'right', width: '25%', paddingRight: 10 },
});

const formattedDate = (date: Moment | Date | string | null) => moment(date).format(datePickerFormat);

const PDFInvoice = ({ invoice, project }: ProjectInvoiceProps) => {
  const { autoCode, groupByPhase, dateRange, estimatedPayDate, due, details } = invoice;
  const { projectCode, projectName } = project;

  const projectTitle = `${invoice?.buyerDetails?.name} • ${projectName}`;

  const renderInvoiceHeader = () => (
    <View style={styles.header}>
      <Image style={styles.logo} src={logo} />

      <Text style={styles.invoiceNumber}>{autoCode}</Text>
    </View>
  );

  const renderBillingInfo = () => (
    <View style={styles.billingDetails}>
      {invoice?.sellerDetails && (
        <View>
          <Text>Invoice From:</Text>
          <Text>{invoice.sellerDetails.name}</Text>
          {ORDERED_KEYS.map(key => (
            <Text key={key}>{invoice.sellerDetails?.billingAddress[key]}</Text>
          ))}
        </View>
      )}
      {invoice?.buyerDetails && (
        <View>
          <Text>Invoice To:</Text>
          <Text>{invoice.buyerDetails.name}</Text>
          {ORDERED_KEYS.map(key => (
            <Text key={key}>{invoice.buyerDetails?.billingAddress[key]}</Text>
          ))}
        </View>
      )}
    </View>
  );

  const renderBillingDetails = () => (
    <View style={styles.billingDetails}>
      <View>
        <Text>Billing Range:</Text>
        {dateRange && <Text>{dateRange.map(formattedDate).join(' - ')}</Text>}
      </View>
      <View>
        <Text>Due Date:</Text>
        <Text>{estimatedPayDate ? formattedDate(estimatedPayDate) : 'N/A'}</Text>
      </View>
    </View>
  );

  const renderTableHeader = (title: string) => (
    <View style={styles.tableHeader}>
      <Text style={styles.column}>{title}</Text>
      <Text style={styles.textRight}>Billable Hours</Text>
      <Text style={styles.textRight}>Billable Rate</Text>
      <Text style={styles.textRight}>Billable Total</Text>
    </View>
  );

  const renderTableSummary = (totalBillableHours: number, totalBillableTotal: number) => (
    <View style={styles.tableSummary}>
      <Text style={styles.column}>Total bill</Text>
      <Text style={styles.textRight}>{displayHoursFromNumber(totalBillableHours)}</Text>
      <Text style={styles.textRight}></Text>
      <Text style={styles.textRight}>{formatCurrency(totalBillableTotal)}</Text>
    </View>
  );

  const renderTableRows = (groupedDetails: TimesheetSummary[]) =>
    groupedDetails.map(groupedDetail => (
      <View key={groupedDetail.key} style={styles.tableRow}>
        <Text style={styles.column}>{groupedDetail?.lineItemName ? `${groupedDetail.key} (${groupedDetail.lineItemName})` : groupedDetail.key}</Text>
        <Text style={styles.textRight}>{groupedDetail.billableHours ? displayHoursFromNumber(groupedDetail.billableHours) : ''}</Text>
        <Text style={styles.textRight}>{groupedDetail.billableRate ? formatCurrency(groupedDetail.billableRate) : ''}</Text>
        <Text style={styles.textRight}>
          {formatCurrency(groupedDetail.fixedBillableTotal || groupedDetail.lineItemAmount || groupedDetail.billableTotal)}
        </Text>
      </View>
    ));

  const groupedDetailsByRole = formInvoiceFromTimesheetData(details, 'role').filter(
    pd => pd.isGroup && (pd.billableTotal || pd.fixedBillableTotal || pd.lineItemAmount),
  );
  const groupedDetailsByPhase = formInvoiceFromTimesheetData(details, 'phase').filter(
    pd => pd.isGroup && (pd.billableTotal || pd.fixedBillableTotal || pd.lineItemAmount),
  );

  const summarizedBillableHours = groupedDetailsByRole.reduce((sum, { billableHours }) => sum + (billableHours ? billableHours : 0), 0);
  const summarizedBillableTotal = groupedDetailsByRole.reduce((sum, { billableTotal }) => sum + (billableTotal ? billableTotal : 0), 0);
  const summarizedFixedBillableTotal = groupedDetailsByRole.reduce(
    (sum, { fixedBillableTotal }) => sum + (fixedBillableTotal ? fixedBillableTotal : 0),
    0,
  );
  const summarizedLineItemsTotal = groupedDetailsByRole.reduce((sum, { lineItemAmount }) => sum + (lineItemAmount ? lineItemAmount : 0), 0);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {renderInvoiceHeader()}
        <View style={styles.section}>
          <Text style={styles.title}>PROJECT NAME: {projectTitle}</Text>
          <Text style={styles.title}>PROJECT CODE: {projectCode}</Text>
          <Text style={styles.title}>BILL FOR: {due}</Text>

          {renderBillingInfo()}
        </View>
        {renderBillingDetails()}
        <View style={styles.section}>
          {renderTableHeader('Role')}
          {renderTableRows(groupedDetailsByRole)}
          {renderTableSummary(summarizedBillableHours, summarizedBillableTotal + summarizedFixedBillableTotal + summarizedLineItemsTotal)}
          {groupByPhase && renderTableHeader('Phase')}
          {groupByPhase && renderTableRows(groupedDetailsByPhase)}
        </View>
      </Page>
    </Document>
  );
};

export default PDFInvoice;
