import { useCallback, useEffect, useRef } from 'react';
import { Button, Form, Row, Col, Modal, Space, Typography, List, Avatar, InputNumber, Spin, message } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { Commission } from '../../entities';
import UserSelector from '../users/UserSelector';
import { useProjectCommission } from '../../dal/useProjectCommission';

type Props = {
  projectId: string;
  onCancel: () => void;
};

const UsersCommissionModal = ({ projectId, onCancel }: Props) => {
  const [form] = useForm();

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const { commission = [], mutate, isLoading, saveCommission, deleteCommission } = useProjectCommission(projectId);

  const submitCommission = useCallback(
    async (commission: Commission) => {
      await saveCommission(commission);
      await mutate();
      form.resetFields();
    },
    [form, mutate, saveCommission],
  );

  const onDelete = useCallback(
    async id => {
      await deleteCommission(id);
      await mutate();
    },
    [deleteCommission, mutate],
  );

  const handleAddMember = useCallback(
    async (values: { userId: number }) => {
      const existingUser = commission.find(item => item.userId === values.userId);

      if (existingUser) return;

      const newMember = { userId: values.userId, value: 0, projectId } as Commission;

      submitCommission(newMember);
      mutate();
    },
    [commission, mutate, projectId, submitCommission],
  );

  const handleValueChange = useCallback(
    (userId: number, value: number) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        const existingUser = commission.find(user => user.userId === userId);

        if (existingUser) {
          submitCommission({ ...existingUser, value });
          message.success('Commission updated');
        }
      }, 500);
    },
    [commission, submitCommission],
  );

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  return (
    <Modal
      open={!!commission}
      title={<Typography.Text>Project commission</Typography.Text>}
      onCancel={onCancel}
      destroyOnClose
      footer={[
        <Button key="close" onClick={onCancel}>
          Close
        </Button>,
      ]}
    >
      <Spin spinning={isLoading}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Form form={form} layout="vertical" onFinish={handleAddMember}>
            <Row gutter={16} align="middle">
              <Col span={12}>
                <Form.Item label="User" name="userId">
                  <UserSelector style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Button icon={<PlusOutlined />} htmlType="submit" type="link" style={{ padding: 0 }}>
                  Add
                </Button>
              </Col>
            </Row>
          </Form>

          <List
            dataSource={commission}
            renderItem={userWithCommission => (
              <List.Item
                key={userWithCommission.id}
                actions={[<Button icon={<CloseOutlined />} type="text" onClick={() => onDelete(userWithCommission.id)} />]}
              >
                <List.Item.Meta
                  avatar={<Avatar src={userWithCommission.picture} size="large" />}
                  title={userWithCommission.userName}
                  description={userWithCommission.email}
                />
                <InputNumber
                  min={0}
                  max={100}
                  controls={false}
                  formatter={value => `${value}%`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  value={userWithCommission.value}
                  onChange={value => handleValueChange(userWithCommission.userId, value || 0)}
                />
              </List.Item>
            )}
          />
        </Space>
      </Spin>
    </Modal>
  );
};

export default UsersCommissionModal;
