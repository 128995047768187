import { useCallback, useState } from 'react';
import { Button, List, PageHeader, Table, Typography } from 'antd';
import { PercentageOutlined } from '@ant-design/icons';

import moment from 'moment';
import { Link } from 'react-router-dom';

import TimesheetTabsControls from '../../components/Timesheet/TimesheetTabsControls';
import { useCommissionReport } from '../../dal/useCommissionReport';
import { CommissionReportItem } from '../../entities';
import { formatCurrency, formatDate, nullableDataSorter } from '../../common/utils';
import { usePermissions } from '../../common/usePermissions/usePermissions';
import ProjectsCommissionModal from './CommissionReport.commissionModal';
import { useSession } from '../../dal';
import useSWR from 'swr';

const CommissionReport = () => {
  const { ability } = usePermissions();
  const { session } = useSession();

  const [activeDay, setActiveDay] = useState(moment().startOf('month'));
  const [openCommission, setOpenCommission] = useState<boolean>(false);

  const { commissionReport, mutate } = useCommissionReport(activeDay.format('YYYY-MM-DD'));
  const { data } = useSWR(`/timesheets/for-month?startDate=${activeDay.format('YYYY-MM-DD')}`);

  const getPreviousMonth = useCallback(() => {
    setActiveDay(prevDay => prevDay.clone().subtract(1, 'month'));
  }, []);

  const getNextMonth = useCallback(() => {
    setActiveDay(prevDay => prevDay.clone().add(1, 'month'));
  }, []);

  const summary = useCallback(pageData => {
    let summaryBillAmount = 0;
    let summaryCommissionAmount = 0;

    pageData.forEach(({ key, billAmount, commissionAmount }) => {
      summaryBillAmount += billAmount;
      summaryCommissionAmount += commissionAmount;
    });

    return (
      <>
        <Table.Summary.Row className="role-group-row">
          <Table.Summary.Cell index={0} colSpan={4}>
            Total
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} align="right">
            {formatCurrency(summaryBillAmount)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} colSpan={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={3} align="right">
            {formatCurrency(summaryCommissionAmount)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4}></Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  }, []);

  if (!data?.data) return null;

  return (
    <div className="flex flex-col h-full">
      <PageHeader
        title={activeDay.format('MMMM, YYYY')}
        className="payroll-header"
        subTitle={<TimesheetTabsControls onGetPrevious={getPreviousMonth} onGetNext={getNextMonth} />}
        extra={
          ability.can('update', 'Commission') && (
            <Button icon={<PercentageOutlined />} onClick={() => setOpenCommission(true)}>
              Add commission
            </Button>
          )
        }
      />

      <div className="ant-space-vertical">
        <Table bordered size="small" dataSource={commissionReport} rowKey="key" pagination={false} summary={summary}>
          <Table.Column
            dataIndex="clientName"
            title="Client"
            render={(value, row) => (
              <Link onClick={event => event.stopPropagation()} to={`/clients/${row.clientId}`} rel="noopener noreferrer" target="_blank">
                {value}
              </Link>
            )}
            sorter={(a: CommissionReportItem, b: CommissionReportItem) => nullableDataSorter(a.clientName, b.clientName)}
          />
          <Table.Column
            dataIndex="projectName"
            title="Project"
            render={(value, row) => (
              <Link onClick={event => event.stopPropagation()} to={`/projects/${row.projectId}`} rel="noopener noreferrer" target="_blank">
                {value}
              </Link>
            )}
            sorter={(a: CommissionReportItem, b: CommissionReportItem) => nullableDataSorter(a.projectName, b.projectName)}
          />
          <Table.Column
            dataIndex="projectCode"
            title="Code"
            render={(value, row) => (
              <Link onClick={event => event.stopPropagation()} to={`/projects/${row.projectId}`} rel="noopener noreferrer" target="_blank">
                {value}
              </Link>
            )}
            sorter={(a: CommissionReportItem, b: CommissionReportItem) =>
              nullableDataSorter(parseInt(a.projectCode.substring(1), 10), parseInt(b.projectCode.substring(1), 10))
            }
          />
          <Table.Column
            dataIndex="invoiceNumber"
            title="Invoice Number"
            render={(value: string[]) => (
              <List
                size="small"
                dataSource={value}
                renderItem={invoiceNumber => (
                  <List.Item style={{ padding: 0 }}>
                    <Typography.Text>{invoiceNumber}</Typography.Text>
                  </List.Item>
                )}
              />
            )}
          />
          <Table.Column
            dataIndex="billAmount"
            title="Bill Amount"
            align="right"
            render={formatCurrency}
            sorter={(a: CommissionReportItem, b: CommissionReportItem) => nullableDataSorter(a.billAmount, b.billAmount)}
          />
          <Table.Column
            dataIndex="personName"
            title="Person"
            render={(value, { commissionPercent }: CommissionReportItem) => (
              <Typography.Text>
                {value} ({commissionPercent}%)
              </Typography.Text>
            )}
            sorter={(a: CommissionReportItem, b: CommissionReportItem) => nullableDataSorter(a.personName, b.personName)}
          />
          <Table.Column
            dataIndex="commissionAmount"
            title="Commission"
            align="right"
            render={formatCurrency}
            sorter={(a: CommissionReportItem, b: CommissionReportItem) => nullableDataSorter(a.commissionAmount, b.commissionAmount)}
          />
          <Table.Column
            dataIndex="invoiceNumber"
            title="Reported commission"
            render={(_, row: CommissionReportItem) => {
              const dataSource = data.data.filter(r => r.project.id === row.projectId && r.user.id === row.userId);
              if (dataSource.length === 0) return null;
              return (
                <>
                  <Table bordered dataSource={dataSource} size="small" pagination={false} className="nested-table">
                    <Table.Column dataIndex="entryDate" title="Entry date" render={d => formatDate(d)}></Table.Column>
                    <Table.Column dataIndex="cost" title="Amount" render={formatCurrency}></Table.Column>
                    <Table.Column dataIndex={['task', 'name']} title="Task"></Table.Column>
                  </Table>
                </>
              );
            }}
          />
        </Table>
      </div>

      {openCommission && ability.can('update', 'Commission') && (
        <ProjectsCommissionModal
          userId={session?.id}
          onCancel={async () => {
            setOpenCommission(false);
            await mutate();
          }}
        />
      )}
    </div>
  );
};

export default CommissionReport;
